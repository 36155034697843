export default [
  {
    path: '/otaManagement',
    name: 'otaManagement',
    component: () => import(/* webpackChunkName: "otaManagement" */ './index'),
    meta: {
      type: 'otaManagement'
    },
    redirect: '/otaManagement/otaList',
    children: [
      {
        path: 'otaList',
        name: 'otaList',
        component: () => import(/* webpackChunkName: "otaManagement" */ './otaList')
      },
      {
        path: 'versionManagementOta',
        name: 'versionManagementOta',
        component: () => import(/* webpackChunkName: "otaManagement" */ './versionManagement')
      },
      {
        path: 'publishOta',
        name: 'publishOta',
        component: () => import(/* webpackChunkName: "otaManagement" */ './publishOta')
      },
      {
        path: 'processProgressOta',
        component: () => import(/* webpackChunkName: "otaManagement" */ './processProgress'),
        redirect: '/processProgressOta/index',
        children: [
          {
            path: 'index',
            name: 'processProgressOta',
            component: () =>
              import(
                /* webpackChunkName: "otaManagement" */ './processProgress/views/operateList.vue'
              )
          },
          {
            path: 'otaDetail/:versionPublishId',
            name: 'processProgressOtaDetail',
            component: () =>
              import(
                /* webpackChunkName: "otaManagement" */ './processProgress/views/otaDetail.vue'
              )
          }
        ]
      }
    ]
  }
]
